// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .team-section {
    margin-top: 40px;
  }
  
  .team-member {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .team-member img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .team-member p {
    font-size: 18px;
  }
  
  @media (max-width: 768px) {
    .team-member {
      flex-direction: column;
      align-items: center;
    }
  
    .team-member img {
      margin-bottom: 10px;
    }
  
    .team-member p {
      text-align: center;
      font-size: 16px;
    }
  }
  
  @media (max-width: 480px) {
    .about-us-container {
      padding: 10px;
    }
  
    .team-member p {
      font-size: 14px;
    }
  
    .team-member img {
      width: 150px;
      height: 150px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/AboutUs.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE;MACE,sBAAsB;MACtB,mBAAmB;IACrB;;IAEA;MACE,mBAAmB;IACrB;;IAEA;MACE,kBAAkB;MAClB,eAAe;IACjB;EACF;;EAEA;IACE;MACE,aAAa;IACf;;IAEA;MACE,eAAe;IACjB;;IAEA;MACE,YAAY;MACZ,aAAa;IACf;EACF","sourcesContent":[".about-us-container {\n    padding: 20px;\n    font-family: Arial, sans-serif;\n  }\n  \n  .team-section {\n    margin-top: 40px;\n  }\n  \n  .team-member {\n    display: flex;\n    align-items: center;\n    margin-bottom: 20px;\n  }\n  \n  .team-member img {\n    width: 200px;\n    height: 200px;\n    border-radius: 50%;\n    margin-right: 20px;\n  }\n  \n  .team-member p {\n    font-size: 18px;\n  }\n  \n  @media (max-width: 768px) {\n    .team-member {\n      flex-direction: column;\n      align-items: center;\n    }\n  \n    .team-member img {\n      margin-bottom: 10px;\n    }\n  \n    .team-member p {\n      text-align: center;\n      font-size: 16px;\n    }\n  }\n  \n  @media (max-width: 480px) {\n    .about-us-container {\n      padding: 10px;\n    }\n  \n    .team-member p {\n      font-size: 14px;\n    }\n  \n    .team-member img {\n      width: 150px;\n      height: 150px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
